var render = function () {
  var _vm$transactions;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.correct ? _c('div', {
    staticClass: "contract-wrapper"
  }, [_c('NetworkLabel', {
    attrs: {
      "network": _vm.network
    }
  }), _c('div', {
    staticClass: "d-block d-md-flex pl-3"
  }, [_vm._m(0), _c('div', {
    staticClass: "align-self-end pl-md-3 pl-5 contract-id d-flex"
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(_vm._s(_vm.contractId))]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_vm._v(_vm._s(_vm._f("tx")(_vm.contractId)))]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.contractId,
      expression: "contractId"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedDisplay,
      visible: _vm.copiedDisplay
    }
  }, [_vm._v("Copied")])])]), _c('div', {
    staticClass: "contract-details-wrapper pb-5"
  }, [_c('div', {
    staticClass: "d-block d-md-flex"
  }, [_c('div', {
    staticClass: "col-6 p-0"
  }, [_c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_vm._v("Total transactions")]), _vm.total ? _c('div', [_vm._v(_vm._s(_vm.total))]) : _vm.total == 0 ? _c('div', [_vm._v("0")]) : _c('div', {
    staticClass: "pl-3 pt-3"
  }, [_c('div', {
    staticClass: "dot-flashing"
  })])]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://viewblock.io/arweave/address/".concat(_vm.contractId, "?network=").concat(_vm.network, "&dre=").concat(_vm.activeDre[_vm.network].dre)
    }
  }, [_vm._v("Viewblock link")])])])])])]), _c('div', [_c('b-nav', {
    staticClass: "contract-tabs",
    attrs: {
      "tabs": ""
    },
    on: {
      "changed": _vm.onInput
    }
  }, [_c('b-nav-item', {
    attrs: {
      "to": "?network=".concat(_vm.network, "#"),
      "active": _vm.$route.hash === '#' || _vm.$route.hash === ''
    },
    on: {
      "click": function click($event) {
        return _vm.onInput(_vm.$route.hash);
      }
    }
  }, [_vm._v(" Transactions ")])], 1), _c('div', {
    staticClass: "tab-content"
  }, [_c('div', {
    staticClass: "p-2",
    class: ['tab-pane', {
      active: _vm.$route.hash === '#' || _vm.$route.hash === ''
    }]
  }, [!_vm.noTransactionsDetected ? _c('div', [_c('div', {
    staticClass: "d-block d-sm-flex justify-content-between mb-4"
  }, [_c('b-col', {
    staticClass: "my-1 d-sm-flex d-block py-3 px-0",
    attrs: {
      "lg": "9"
    }
  }, [_c('p', {
    staticClass: "filter-header mr-4 ml-2"
  }, [_vm._v("Type")]), _c('b-form-radio-group', {
    staticClass: "confirmation-status-group",
    attrs: {
      "id": "confirmation-status-group",
      "name": "confirmation-status-group"
    },
    on: {
      "change": _vm.refreshData
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_c('div', {
    staticClass: "confirmation-status-item"
  }, [_c('b-form-radio', {
    attrs: {
      "value": "all"
    }
  }, [_vm._v("All")])], 1), _c('div', {
    staticClass: "confirmation-status-item"
  }, [_c('b-form-radio', {
    attrs: {
      "value": "interaction"
    }
  }, [_vm._v("Interaction")])], 1), _c('div', {
    staticClass: "confirmation-status-item"
  }, [_c('b-form-radio', {
    attrs: {
      "value": "contract"
    }
  }, [_vm._v("Contract")])], 1)])], 1), _c('b-button', {
    staticClass: "btn btn-refresh d-flex justify-content-center align-items-center rounded-pill mb-3 mb-sm-0",
    on: {
      "click": _vm.refreshData
    }
  }, [_vm.transactionsLoaded ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Refresh data")]) : _c('div', [_c('div', {
    staticClass: "dot-flashing"
  })])])], 1), _c('div', [_vm.transactionsLoaded ? _c('TxList', {
    attrs: {
      "paging": _vm.pages
    },
    on: {
      "page-clicked": _vm.onPageClicked
    }
  }, [((_vm$transactions = _vm.transactions) === null || _vm$transactions === void 0 ? void 0 : _vm$transactions.length) > 0 ? _c('b-table', {
    ref: "table",
    attrs: {
      "id": "transactions-table",
      "stacked": "md",
      "hover": "",
      "items": _vm.transactions,
      "fields": _vm.fields,
      "busy": !_vm.transactionsLoaded
    },
    on: {
      "row-clicked": _vm.rowClicked
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [data.item.transactionType == 'interaction' ? _c('img', {
          staticClass: "type-icon",
          attrs: {
            "src": require("@/assets/icons/interaction.svg"),
            "alt": "",
            "srcset": ""
          }
        }) : _vm._e(), data.item.transactionType == 'contract' ? _c('img', {
          staticClass: "type-icon",
          attrs: {
            "src": require("@/assets/icons/contract-ico.svg"),
            "alt": "",
            "srcset": ""
          }
        }) : _vm._e(), _c('p', {
          staticClass: "mb-0 ml-2"
        }, [_vm._v(_vm._s(data.item.transactionType.toUpperCase()))])])];
      }
    }, {
      key: "cell(id)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex"
        }, [data.item.transactionType == 'contract' ? _c('a', {
          attrs: {
            "href": "/#/app/contract/".concat(data.item.id, "?network=").concat(_vm.network, "&dre=").concat(_vm.activeDre[_vm.network].dre)
          }
        }, [_vm._v(" " + _vm._s(_vm._f("tx")(data.item.id)))]) : _vm._e(), data.item.transactionType == 'interaction' ? _c('a', {
          attrs: {
            "href": "/#/app/interaction/".concat(data.item.id, "?network=").concat(_vm.network, "&dre=").concat(_vm.activeDre[_vm.network].dre)
          }
        }, [_vm._v(" " + _vm._s(_vm._f("tx")(data.item.id)))]) : _vm._e(), _c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard",
            value: data.item.id,
            expression: "data.item.id"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: function value(_ref) {
              var event = _ref.event;
              return event.stopPropagation();
            },
            expression: "({ event }) => event.stopPropagation()",
            arg: "success"
          }],
          staticClass: "flaticon-copy-to-clipboard small",
          attrs: {
            "title": "Copy to clipboard"
          }
        })])];
      }
    }, {
      key: "cell(bundlerId)",
      fn: function fn(data) {
        return [data.item.bundlerId ? _c('div', {
          staticClass: "d-flex"
        }, [_c('a', {
          attrs: {
            "href": "".concat(_vm.daysAgo(data.item.timestamp) > 1 ? "https://viewblock.io/arweave/tx/".concat(data.item.bundlerId) : "https://arweave.net/".concat(data.item.bundlerId)),
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(_vm._f("tx")(data.item.bundlerId)))]), _c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard",
            value: data.item.bundlerId,
            expression: "data.item.bundlerId"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: function value(_ref2) {
              var event = _ref2.event;
              return event.stopPropagation();
            },
            expression: "({ event }) => event.stopPropagation()",
            arg: "success"
          }],
          staticClass: "flaticon-copy-to-clipboard small",
          attrs: {
            "title": "Copy to clipboard"
          }
        })]) : _c('span', [_vm._v("N/A")])];
      }
    }, {
      key: "cell(block_height)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.blockHeight) + " ")];
      }
    }], null, false, 1725166086)
  }) : _vm._e()], 1) : _vm._e(), !_vm.transactionsLoaded ? _c('div', _vm._l(15, function (n) {
    return _c('div', {
      key: n,
      staticClass: "preloader text-preloader tx-preloader"
    });
  }), 0) : _vm._e()], 1)]) : _c('div', {
    staticClass: "transactions-wrapper"
  }, [_vm._v("Contract has no transactions!")])])])], 1)], 1) : _vm._e(), !_vm.correct ? _c('div', [_c('Error')], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contract-header-wrapper"
  }, [_c('div', {
    staticClass: "flaticon-file-signature m-0-auto"
  }), _c('div', {
    staticClass: "align-self-end contract-header"
  }, [_c('span', [_vm._v("Creator")])])]);
}]

export { render, staticRenderFns }