var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('ul', {
    staticClass: "statuses"
  }, [_vm._m(0), _vm._l(_vm.status[this.network], function (value, key) {
    return _c('li', {
      key: key,
      staticClass: "status my-1",
      class: key == _vm.activeDreName ? 'selected' : '',
      on: {
        "click": function click($event) {
          return _vm.changeDre(value, key);
        }
      }
    }, [_c('div', {
      staticClass: "ml-5",
      class: key == _vm.activeDreName ? 'flaticon-check' : 'empty'
    }), _c('div', {
      staticClass: "led m-4",
      class: value.isActive ? 'led-green' : 'led-red'
    }), _c('div', [_c('p', {
      staticClass: "my-0 status-text"
    }, [_c('a', {
      attrs: {
        "href": value.link
      },
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.goToDre(key);
        }
      }
    }, [_vm._v(_vm._s(key.substring(0, 3).toUpperCase() + '-' + key.substring(3).toUpperCase()))])]), _c('p', {
      staticClass: "mb-0 sdk-text"
    }, [_vm._v("Warp Contracts: " + _vm._s(value.warp || 'N/A'))])])]);
  })], 2)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "text-center"
  }, [_vm._v(" Select node which will serve as a "), _c('a', {
    attrs: {
      "href": "https://docs.warp.cc/docs/dre/overview",
      "target": "_blank"
    }
  }, [_vm._v("contract state evaluator")]), _vm._v(". ")]);
}]

export { render, staticRenderFns }