var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contract-wrapper error-wrapper"
  }, [_vm._v(" Oops! "), _c('div', [_vm._v("Seems like we could not find the transaction you are searching for.")]), _c('div', {
    staticClass: "flaticon-sad-error"
  })]);
}]

export { render, staticRenderFns }