var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar', {
    staticClass: "header d-print-none app-header"
  }, [_c('b-nav', [_c('div', {
    staticClass: "logo-container align-self-center"
  }, [_c('a', {
    attrs: {
      "href": _vm.logoUrl
    }
  }, [_c('div', {
    staticClass: "logo-image-container"
  }, [_c('img', {
    staticClass: "d-none d-md-block logo-image first",
    staticStyle: {
      "width": "120px"
    },
    attrs: {
      "src": "/warp-logo-full.svg"
    }
  }), _c('img', {
    staticClass: "d-block d-md-none logo-image first",
    staticStyle: {
      "width": "32px"
    },
    attrs: {
      "src": "/warp-logo-mobile.svg"
    }
  })])])])]), _c('b-nav', {
    staticClass: "w-25 w-md-auto"
  }, [_c('b-form', {
    staticClass: "ml-1",
    attrs: {
      "inline": ""
    }
  }, [_c('b-form-group', {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm.showSearchInputInHeader ? _c('b-input-group', {
    staticClass: "input-group-no-border",
    staticStyle: {
      "paddingleft": "10px"
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [!_vm.searching ? _c('b-input-group-text', [_c('i', {
          staticClass: "fi flaticon-search-2"
        })]) : _vm._e(), _vm.searching ? _c('b-input-group-text', {
          staticClass: "blue"
        }, [_c('b-spinner', {
          staticStyle: {
            "width": "20px",
            "height": "20px"
          },
          attrs: {
            "label": "Busy"
          }
        })], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 205916034)
  }, [_c('vue-typeahead-bootstrap', {
    staticClass: "input-search",
    attrs: {
      "ieCloseFix": false,
      "data": _vm.foundContracts,
      "serializer": function serializer(item) {
        return item.type == 'pst' ? item.pst_ticker.substring(0, 3).toLowerCase() == _vm.query.substring(0, 3).toLowerCase() ? item.pst_ticker : item.pst_name : item.id;
      },
      "placeholder": _vm.searchBarText,
      "maxMatches": 1000
    },
    on: {
      "hit": _vm.goToContract,
      "input": _vm.lookupContracts
    },
    scopedSlots: _vm._u([{
      key: "suggestion",
      fn: function fn(_ref) {
        var data = _ref.data,
            htmlText = _ref.htmlText;
        return [_c('div', {
          staticClass: "d-block d-md-flex justify-content-between"
        }, [data.type == 'pst' ? _c('span', {
          staticClass: "d-none d-md-block text-left"
        }, [_vm._v(_vm._s(data.id))]) : _vm._e(), _c('span', {
          staticClass: "suggestion-type d-block d-md-none"
        }, [_vm._v(_vm._s(data.type))]), data.type == 'pst' ? _c('span', {
          staticClass: "text-nowrap pst-match",
          domProps: {
            "innerHTML": _vm._s(htmlText)
          }
        }) : _c('span', {
          staticClass: "text-nowrap",
          domProps: {
            "innerHTML": _vm._s(htmlText)
          }
        }), data.type == 'pst' ? _c('span', {
          staticClass: "d-block d-md-none text-nowrap text-left"
        }, [_vm._v(_vm._s(data.id))]) : _vm._e(), _c('div', {
          staticStyle: {
            "width": "12%"
          }
        }, [_c('span', {
          staticClass: "d-none d-md-block suggestion-type mt-1"
        }, [_vm._v(_vm._s(data.type))])])])];
      }
    }], null, false, 3871253473),
    model: {
      value: _vm.query,
      callback: function callback($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  }), _vm.noResultsInfo ? _c('div', {
    staticClass: "no-result list-group shadow vbt-autcomplete-list"
  }, [_c('span', [_vm._v(_vm._s(_vm.noResultsInfo))])]) : _vm._e()], 1) : _c('a', {
    attrs: {
      "href": "javascript:window.history.back()"
    }
  }, [_c('i', {
    staticClass: "fa flaticon-chevron-back"
  })])], 1)], 1)], 1), _c('b-nav'), _c('b-nav', {
    staticClass: "align-items-center flex-grow-1 justify-content-end"
  }, [_c('div', {
    staticClass: "d-none d-md-flex align-items-center"
  }, [_c('div', {
    staticClass: "text-uppercase"
  }, [_c('div', {
    staticClass: "d-flex flex-column p-1"
  }, [_c('span', [_vm._v("height")])])]), _c('span', {
    staticClass: "pr-5 pl-2 height"
  }, [_vm._v(_vm._s(_vm.networkHeight))])]), _c('div', [_c('b-dropdown', {
    staticClass: "m-md-2",
    attrs: {
      "variant": "outline-primary"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.currentNetwork) + " "), _c('b-icon-chevron-compact-down')];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "active": _vm.currentNetwork == 'Mainnet'
    },
    on: {
      "click": function click($event) {
        return _vm.switchToNetwork('mainnet');
      }
    }
  }, [_vm._v("Mainnet")]), _c('b-dropdown-item', {
    attrs: {
      "active": _vm.currentNetwork == 'Testnet'
    },
    on: {
      "click": function click($event) {
        return _vm.switchToNetwork('testnet');
      }
    }
  }, [_vm._v("Testnet")]), _c('b-dropdown-item', {
    attrs: {
      "active": _vm.currentNetwork == 'Devnet'
    },
    on: {
      "click": function click($event) {
        return _vm.switchToNetwork('devnet');
      }
    }
  }, [_vm._v("Devnet")])], 1)], 1), _c('b-button', {
    staticClass: "btn btn-modal-outline rounded-pill login-btn outline-primary mr-1",
    on: {
      "click": _vm.toggleDreModal
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-space-between"
  }, [_c('div', {
    staticClass: "led mr-3",
    class: _vm.activeDre.isActive == false ? 'led-red' : 'led-green'
  }), _c('p', [_vm._v(" " + _vm._s(_vm.activeDre[this.network].dre.substring(0, 3).toUpperCase() + '-' + _vm.activeDre[this.network].dre.substring(3).toUpperCase()) + " ")])])]), _c('b-button', {
    staticClass: "btn btn-modal rounded-pill login-btn",
    class: {
      accNavActive: _vm.modalVisible
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm.walletAccount ? _c('div', [_c('p', [_c('img', {
    attrs: {
      "src": require("../../assets/icons/wallet-svgrepo-com.svg"),
      "alt": ""
    }
  }), _vm._v(_vm._s(_vm._f("tx")(_vm.walletAccount)))])]) : _c('p', [_vm._v("Login")])])], 1), _vm.dreModalVisible ? _c('DreStatus', {
    attrs: {
      "activeDre": _vm.activeDre
    },
    on: {
      "changeActiveDre": _vm.updateDre
    }
  }) : _vm._e(), _vm.modalVisible ? _c('AccountNavigation') : _vm._e(), _vm.modalVisible || _vm.dreModalVisible ? _c('div', {
    staticClass: "nav-closing",
    on: {
      "click": _vm.toggleAccNav
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }