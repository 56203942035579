var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "unauthorized-view"
  }, [!_vm.loading ? _c('div', {
    staticClass: "buttons-container"
  }, [_c('p', {
    staticClass: "nav-header"
  }, [_vm._v("Choose wallet")]), _c('b-button', {
    staticClass: "btn btn-modal rounded-pill",
    on: {
      "click": function click($event) {
        return _vm.$emit('handleMetamask');
      }
    }
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("../../assets/icons/MetaMask_Fox.svg"),
      "alt": "metamask logo icon"
    }
  })]), _vm._v(" Metamask")]), _c('b-button', {
    staticClass: "btn btn-modal rounded-pill",
    on: {
      "click": function click($event) {
        return _vm.$emit('handleArweaveapp');
      }
    }
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("../../assets/icons/arweave-ar-logo.svg"),
      "alt": "arweave logo icon"
    }
  })]), _vm._v(" Arweave.app")]), _c('p', {
    staticClass: "nav-subtext"
  }, [_vm._v("Connect wallet to display your token balance")])], 1) : _c('LoadingSpinner')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }