var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "border-0"
  }, [_c('div', {
    staticClass: "text-justify"
  }, [_c('div', {
    staticClass: "d-flex intro"
  }, [_c('div', {
    staticClass: "p-3"
  }, [_vm._v(" SmartWeave is a data-centered protocol built on top of the Arweave chain that allows to implement smart contracts. The key difference between SmartWeave and other similar solutions is that the contracts can be evaluated on-demand and \"lazily\". ")]), _c('div', {
    staticClass: "p-3"
  }, [_c('b-button', {
    staticClass: "btn-docs rounded-pill",
    attrs: {
      "target": "_blank",
      "href": "https://github.com/warp-contracts/gateway#redstone-smartweave-gateway"
    }
  }, [_vm._v("Check repository")])], 1)]), _c('div', {
    staticClass: "p-3"
  }, [_c('span', {
    staticClass: "info-colored"
  }, [_vm._v("Warp Gateway")]), _vm._v(" is a gateway dedicated to loading SmartWeave contract interactions. It firstly loads information about currently active peers and rank them by the amount of synced blocks and response times. It then loads and index SmartWeave interactions from the newly mined blocks in order to - finally - perform transactions confirmation. Each transaction must be confirmed by at least three nodes to obtain the 'confirmed' status. If it won't pass the test it will be tagged as 'corrupted' which means that it is not part of any block. Being aware of these confirmed and corrupted transactions gives the confidence of having reliable data and not exposing smart contracts to potential harmful incidents. "), _c('br'), _c('br'), _c('span', {
    staticClass: "info-colored"
  }, [_vm._v("Warp Gateway")]), _vm._v(" is a fast and dependable resource which addresses critical problems of loading interactions. It's an open source project which can be used by anyone willing to spin up their own instance of the gateway dedicated to serving its contracts' interactions. ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }