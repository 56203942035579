var render = function () {
  var _vm$interactions;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.correct ? _c('div', {
    staticClass: "contract-wrapper"
  }, [_c('NetworkLabel', {
    attrs: {
      "network": _vm.network
    }
  }), _c('div', {
    staticClass: "d-block d-md-flex pl-3"
  }, [_vm._m(0), _c('div', {
    staticClass: "align-self-end pl-md-3 pl-5 contract-id d-flex"
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(_vm._s(_vm.contractId))]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_vm._v(_vm._s(_vm._f("tx")(_vm.contractId)))]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.contractId,
      expression: "contractId"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedDisplay,
      visible: _vm.copiedDisplay
    }
  }, [_vm._v("Copied")])])]), _c('div', {
    staticClass: "contract-details-wrapper pb-5"
  }, [_c('div', {
    staticClass: "d-block d-md-flex"
  }, [_c('div', {
    staticClass: "col-6 p-0"
  }, [_c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_vm._v("Creator")]), _c('div', {
    staticClass: "d-flex"
  }, [_vm.owner ? _c('div', {
    staticClass: "align-self-end d-flex"
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_c('a', {
    attrs: {
      "href": "/#/app/creator/".concat(_vm.owner, "?network=").concat(_vm.network, "&dre=").concat(_vm.activeDre[_vm.network].dre)
    }
  }, [_vm._v(_vm._s(_vm.owner))])]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_c('a', {
    attrs: {
      "href": "/#/app/creator/".concat(_vm.owner, "?network=").concat(_vm.network, "&dre=").concat(_vm.activeDre[_vm.network].dre)
    }
  }, [_vm._v(_vm._s(_vm._f("tx")(_vm.owner)))])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.owner,
      expression: "owner"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopyOwner,
      expression: "onCopyOwner",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedDisplayOwner,
      visible: _vm.copiedDisplayOwner
    }
  }, [_vm._v(" Copied ")])]) : _c('div', {
    staticClass: "pl-3 pt-3"
  }, [_c('div', {
    staticClass: "dot-flashing"
  })])])]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_vm._v("Bundler id")]), _c('div', {
    staticClass: "d-flex"
  }, [_vm.bundler_id ? _c('div', {
    staticClass: "align-self-end d-flex"
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(_vm._s(_vm.bundler_id))]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_vm._v(_vm._s(_vm._f("tx")(_vm.bundler_id)))]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.bundler_id,
      expression: "bundler_id"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopyBundlerId,
      expression: "onCopyBundlerId",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedBundlerId,
      visible: _vm.copiedBundlerId
    }
  }, [_vm._v(" Copied ")])]) : _c('div', [_vm._v("N/A")])])]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "cell-header pb-2"
  }, [_vm._v("Contract data")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "flaticon-question-tooltip",
    attrs: {
      "title": "Contract data available only for contracts deployed through bundler. Either initial state if it is set in the data field by default or custom data defined by the contract developer."
    }
  })]), !_vm.loadedContractData ? _c('div', {
    staticClass: "pl-3 pt-3"
  }, [_c('div', {
    staticClass: "dot-flashing"
  })]) : _c('div', [_vm.contractData ? _c('div', [_c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.contractData
    }
  }, [_vm._v("Link")])]) : _c('div', {
    staticClass: "flaticon-cross"
  })])])]), _c('div', {
    staticClass: "col-6 p-0"
  }, [_c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_vm._v("Source transaction id")]), _c('div', {
    staticClass: "d-flex"
  }, [_vm.sourceTxId ? _c('div', {
    staticClass: "align-self-end d-flex"
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_c('a', {
    attrs: {
      "href": "/#/app/source/".concat(_vm.sourceTxId, "?network=").concat(_vm.network)
    }
  }, [_vm._v(_vm._s(_vm.sourceTxId))])]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_c('a', {
    attrs: {
      "href": "/#/app/source/".concat(_vm.sourceTxId).concat(_vm.network)
    }
  }, [_vm._v(_vm._s(_vm._f("tx")(_vm.sourceTxId)))])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.sourceTxId,
      expression: "sourceTxId"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopySourceTxId,
      expression: "onCopySourceTxId",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedDisplaySourceTxId,
      visible: _vm.copiedDisplaySourceTxId
    }
  }, [_vm._v(" Copied ")])]) : _c('div', {
    staticClass: "pl-3 pt-3"
  }, [_c('div', {
    staticClass: "dot-flashing"
  })])])]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_vm._v("PST Ticker")]), _c('div', [_vm._v(_vm._s(_vm.pst_ticker ? _vm.pst_ticker : 'N/A'))])]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_vm._v("PST Name")]), _c('div', [_vm._v(_vm._s(_vm.pst_name ? _vm.pst_name : 'N/A'))])])])])]), _c('div', [_c('b-nav', {
    staticClass: "contract-tabs",
    attrs: {
      "tabs": ""
    },
    on: {
      "changed": _vm.onInput
    }
  }, [_c('b-nav-item', {
    staticClass: "transactions-tab",
    attrs: {
      "to": "?network=".concat(_vm.network, "#"),
      "active": _vm.$route.hash === '#' || _vm.$route.hash === ''
    },
    on: {
      "click": function click($event) {
        return _vm.onInput(_vm.$route.hash);
      }
    }
  }, [_vm._v(" Transactions "), _vm.total ? _c('b-badge', {
    staticClass: "ml-2",
    attrs: {
      "variant": "info"
    }
  }, [_vm.total ? _c('div', [_vm._v(_vm._s(_vm.total))]) : _vm._e()]) : _vm.total == 0 ? _c('b-badge', {
    staticClass: "ml-2",
    attrs: {
      "variant": "info"
    }
  }, [_c('div', [_vm._v("0")])]) : _c('div', {
    staticClass: "dot-flashing ml-4 mr-2"
  })], 1), _c('b-nav-item', {
    staticClass: "transactions-tab",
    attrs: {
      "to": "?network=".concat(_vm.network, "#code"),
      "active": _vm.$route.hash === '#code'
    },
    on: {
      "click": function click($event) {
        return _vm.onInput(_vm.$route.hash);
      }
    }
  }, [_vm._v(" Code "), _vm.wasmLang === 'loading' ? _c('div', {
    staticClass: "dot-flashing ml-4 mr-2"
  }) : _c('b-badge', {
    staticClass: "ml-2",
    class: _vm.wasmLang === 'rust' ? 'rust-badge' : 'js-badge'
  }, [_vm.wasmLang ? _c('div', [_vm._v(_vm._s(_vm.wasmLang))]) : _c('div', [_vm._v("JS")])])], 1), _c('b-nav-item', {
    attrs: {
      "to": "?network=".concat(_vm.network, "#state"),
      "active": _vm.$route.hash === '#state'
    },
    on: {
      "click": function click($event) {
        return _vm.onInput(_vm.$route.hash);
      }
    }
  }, [_vm._v(" Initial State ")]), _c('b-nav-item', {
    staticClass: "state-evaluated-tab",
    attrs: {
      "to": "?network=".concat(_vm.network, "#current-state"),
      "active": _vm.$route.hash === '#current-state'
    },
    on: {
      "click": function click($event) {
        return _vm.onInput(_vm.$route.hash);
      }
    }
  }, [_vm._v(" State Evaluated "), _c('b-badge', {
    attrs: {
      "variant": "light"
    }
  }, [_vm.loadedState ? _c('div', [_vm.dre_status == 'evaluated' ? _c('div', {
    staticClass: "flaticon-check"
  }) : _c('div', {
    staticClass: "flaticon-cross"
  })]) : _c('div', {
    staticClass: "pl-3"
  }, [_c('div', {
    staticClass: "dot-flashing mr-1"
  })])])], 1), _c('b-nav-item', {
    attrs: {
      "to": "?network=".concat(_vm.network, "#tags"),
      "active": _vm.$route.hash === '#tags'
    },
    on: {
      "click": function click($event) {
        return _vm.onInput(_vm.$route.hash);
      }
    }
  }, [_vm._v(" Tags ")])], 1), _c('div', {
    staticClass: "tab-content"
  }, [_c('div', {
    staticClass: "p-2",
    class: ['tab-pane', {
      active: _vm.$route.hash === '#' || _vm.$route.hash === ''
    }]
  }, [!_vm.noInteractionsDetected ? _c('div', [_c('div', {
    staticClass: "d-block d-sm-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "btn btn-refresh d-flex justify-content-center align-items-center rounded-pill mb-3",
    on: {
      "click": _vm.refreshData
    }
  }, [_vm.interactionsLoaded ? _c('p', {
    staticClass: "m-0"
  }, [_vm._v("Refresh data")]) : _c('div', [_c('div', {
    staticClass: "dot-flashing"
  })])])], 1), _c('div', [_vm.interactionsLoaded ? _c('TxList', {
    attrs: {
      "paging": _vm.pages
    },
    on: {
      "page-clicked": _vm.onPageClicked
    }
  }, [((_vm$interactions = _vm.interactions) === null || _vm$interactions === void 0 ? void 0 : _vm$interactions.length) > 0 ? _c('b-table', {
    ref: "table",
    attrs: {
      "id": "interactions-table",
      "stacked": "md",
      "hover": "",
      "items": _vm.interactions,
      "fields": _vm.fields,
      "busy": !_vm.interactionsLoaded
    },
    on: {
      "row-clicked": _vm.rowClicked
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('a', {
          attrs: {
            "href": "/#/app/interaction/".concat(data.item.interactionId, "?network=").concat(_vm.network, "&dre=").concat(_vm.activeDre[_vm.network].dre)
          }
        }, [_vm._v(" " + _vm._s(_vm._f("tx")(data.item.interactionId)))]), _c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard",
            value: data.item.interactionId,
            expression: "data.item.interactionId"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: function value(_ref) {
              var event = _ref.event;
              return event.stopPropagation();
            },
            expression: "({ event }) => event.stopPropagation()",
            arg: "success"
          }],
          staticClass: "flaticon-copy-to-clipboard small",
          attrs: {
            "title": "Copy to clipboard"
          }
        })])];
      }
    }, {
      key: "cell(bundlerId)",
      fn: function fn(data) {
        return [data.item.bundlerTxId ? _c('div', {
          staticClass: "d-flex"
        }, [_c('a', {
          attrs: {
            "href": "".concat(_vm.daysAgo(data.item.timestamp) > 1 ? "https://viewblock.io/arweave/tx/".concat(data.item.bundlerTxId) : "https://arweave.net/".concat(data.item.bundlerTxId)),
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(_vm._f("tx")(data.item.bundlerTxId)))]), _c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard",
            value: data.item.bundlerTxId,
            expression: "data.item.bundlerTxId"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: function value(_ref2) {
              var event = _ref2.event;
              return event.stopPropagation();
            },
            expression: "({ event }) => event.stopPropagation()",
            arg: "success"
          }],
          staticClass: "flaticon-copy-to-clipboard small",
          attrs: {
            "title": "Copy to clipboard"
          }
        })]) : _c('span', [_vm._v("N/A")])];
      }
    }, {
      key: "cell(validity)",
      fn: function fn(data) {
        return [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.loadedValidity && _vm.validity && _vm.validity[data.item.interactionId] == true,
            expression: "loadedValidity && validity && validity[data.item.interactionId] == true"
          }],
          staticClass: "flaticon-check centered"
        }), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.loadedValidity && _vm.validity && _vm.validity[data.item.interactionId] == false,
            expression: "loadedValidity && validity && validity[data.item.interactionId] == false"
          }],
          staticClass: "flaticon-cross centered"
        }), _vm.loadedValidity && _vm.validity && !(data.item.interactionId in _vm.validity) ? _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" N/A ")]) : _vm._e(), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.loadedValidity && !_vm.validity,
            expression: "loadedValidity && !validity"
          }],
          staticClass: "text-center"
        }, [_vm._v("N/A")]), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.loadedValidity,
            expression: "!loadedValidity"
          }],
          staticClass: "dot-flashing centered"
        })];
      }
    }, {
      key: "cell(block_height)",
      fn: function fn(data) {
        return [_c('a', {
          attrs: {
            "href": "https://viewblock.io/arweave/block/".concat(data.item.blockId),
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(data.item.blockHeight) + " ")])];
      }
    }, {
      key: "cell(creator)",
      fn: function fn(data) {
        return [_c('a', {
          attrs: {
            "href": "#/app/creator/".concat(data.item.owner, "?network=").concat(_vm.network, "&dre=").concat(_vm.activeDre[_vm.network].dre)
          }
        }, [_vm._v(" " + _vm._s(_vm._f("tx")(data.item.owner)))])];
      }
    }, {
      key: "cell(function)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.function) + " ")];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [!data.item._showDetails ? _c('div', {
          staticClass: "flaticon-chevron-down"
        }) : _c('div', {
          staticClass: "flaticon-chevron-up"
        })];
      }
    }, {
      key: "head(validity)",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-center align-items-center"
        }, [_c('p', {
          staticClass: "m-0 align-bottom"
        }, [_vm._v("valid")]), _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "flaticon-question-tooltip",
          attrs: {
            "title": "Validity only available for evaluated contracts."
          }
        })])];
      },
      proxy: true
    }, {
      key: "row-details",
      fn: function fn(data) {
        return [_vm.errorMessages ? _c('div', {
          staticClass: "tx-error-message-container"
        }, [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.validity && _vm.errorMessages[data.item.interactionId],
            expression: "validity && errorMessages[data.item.interactionId]"
          }]
        }, [_c('p', {
          staticClass: "tx-error-message"
        }, [_vm._v(" Error: "), _c('span', [_vm._v(_vm._s(_vm.errorMessages[data.item.interactionId]))])])])]) : _vm._e(), _c('div', [_c('p', {
          staticClass: "json-header"
        }, [_vm._v("Contract Input:")]), _c('json-viewer', {
          attrs: {
            "value": data.item.tags,
            "expand-depth": 1,
            "copyable": "",
            "sort": "",
            "theme": "json-theme"
          },
          scopedSlots: _vm._u([{
            key: "copy",
            fn: function fn() {
              return [_c('img', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: {
                    "hover": true
                  }
                }],
                staticClass: "jviewer-copy-icon",
                attrs: {
                  "title": "Copy JSON data",
                  "src": require("@/assets/icons/copy-to-clipboard.svg"),
                  "alt": "copy icon"
                }
              })];
            },
            proxy: true
          }], null, true)
        }), _c('hr'), _c('p', {
          staticClass: "json-header"
        }, [_vm._v("Full transaction:")]), _c('json-viewer', {
          attrs: {
            "value": data.item.interaction,
            "expand-depth": 1,
            "copyable": "",
            "sort": "",
            "theme": "json-theme"
          },
          scopedSlots: _vm._u([{
            key: "copy",
            fn: function fn() {
              return [_c('img', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: {
                    "hover": true
                  }
                }],
                staticClass: "jviewer-copy-icon",
                attrs: {
                  "title": "Copy JSON data",
                  "src": require("@/assets/icons/copy-to-clipboard.svg"),
                  "alt": "copy icon"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)];
      }
    }], null, false, 3453622415)
  }) : _vm._e()], 1) : _vm._e(), !_vm.interactionsLoaded ? _c('div', _vm._l(15, function (n) {
    return _c('div', {
      key: n,
      staticClass: "preloader text-preloader tx-preloader"
    });
  }), 0) : _vm._e()], 1)]) : _c('div', {
    staticClass: "interactions-wrapper"
  }, [_vm._v("Contract has no interactions!")])]), _c('div', {
    staticClass: "p-2",
    class: ['tab-pane', {
      active: _vm.$route.hash === '#code'
    }]
  }, [_vm.visitedTabs.includes('#code') ? _c('div', [_vm.loadedContract ? _c('ContractCode', {
    attrs: {
      "contractId": _vm.contractId,
      "sourceId": _vm.sourceTxId,
      "wasm": !!_vm.wasmLang,
      "source": _vm.codeSource
    }
  }) : _c('div', {
    staticClass: "d-flex align-items-center flex-column pt-5"
  }, [_c('LoadingSpinner'), _c('p', [_vm._v("Loading contract code...")])], 1)], 1) : _vm._e()]), _c('div', {
    staticClass: "p-2",
    class: ['tab-pane', {
      active: _vm.$route.hash === '#state'
    }]
  }, [_c('div', [_vm.initState ? _c('ContractState', {
    attrs: {
      "contractId": _vm.contractId,
      "initState": _vm.initState
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "p-2",
    class: ['tab-pane', {
      active: _vm.$route.hash === '#current-state'
    }]
  }, [_c('div', [_vm.currentState ? _c('ContractCurrentState', {
    attrs: {
      "contractId": _vm.contractId,
      "currentState": _vm.currentState,
      "timestamp": _vm.dre_timestamp,
      "sortKey": _vm.dre_sortKey,
      "signature": _vm.dre_signature,
      "stateHash": _vm.dre_stateHash,
      "manifest": _vm.dre_manifest
    }
  }) : !_vm.currentState && _vm.dre_evaluationError ? _c('div', [_vm.dre_status == 'blacklisted' ? _c('span', {
    staticClass: "blacklist-error"
  }, [_vm._v("Contract blacklisted")]) : _vm._e(), _c('json-viewer', {
    staticClass: "mt-4",
    attrs: {
      "value": _vm.dreErrors[0],
      "expand-depth": 1,
      "copyable": "",
      "sort": "",
      "theme": "json-theme"
    }
  })], 1) : !_vm.loadedState ? _c('div', [_vm._v("Loading...")]) : _vm.loadedState && !_vm.currentState && !_vm.dre_evaluationError ? _c('div', [_c('p', {
    staticClass: "text-break"
  }, [_vm._v(" State is evaluated for contracts which are registered as safe (which do not use unsafeClient). If contracts perform internal reads or internal writes on unsafe contracts, these interactions are skipped during the evaluation process. Please contact us to get the instruction on how to submit the contract for evaluation. State is evaluated for contracts which are registered as safe (which do not do not use unsafeClient). If contracts perform internal reads or internal writes on unsafe contracts, these interactions are skipped during the evaluation process. Please contact us to get the instruction on how to submit the contract for evaluation. ")])]) : _vm._e()], 1)]), _c('div', {
    staticClass: "p-2",
    class: ['tab-pane', {
      active: _vm.$route.hash === '#tags'
    }]
  }, [_c('div', [_c('ContractTags', {
    attrs: {
      "contractTags": _vm.tags
    }
  })], 1)])])], 1)], 1) : _vm._e(), !_vm.correct ? _c('div', [_c('Error')], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contract-header-wrapper"
  }, [_c('div', {
    staticClass: "flaticon-file-signature m-0-auto"
  }), _c('div', {
    staticClass: "align-self-end contract-header"
  }, [_c('span', [_vm._v("Contract")])])]);
}]

export { render, staticRenderFns }