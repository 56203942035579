<template>
  <div class="contract-wrapper error-wrapper">
    Oops!
    <div>Seems like we could not find the transaction you are searching for.</div>
    <div class="flaticon-sad-error"></div>
  </div>
</template>

<script>
export default {
  name: 'Error',
};
</script>

<style src="./Error.scss" lang="scss"></style>
