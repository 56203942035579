var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "charts-wrapper"
  }, [_c('NetworkLabel', {
    attrs: {
      "network": _vm.network
    }
  }), _c('div', {
    staticClass: "charts d-none d-md-flex"
  }, [_c('div', {
    staticClass: "chart-single-wrapper"
  }, [_c('div', {
    staticClass: "chart-header"
  }, [_c('div', [_vm._v("Interactions")]), _c('router-link', {
    staticClass: "d-xl-block d-none",
    staticStyle: {
      "margin-left": "auto",
      "cursor": "pointer"
    },
    attrs: {
      "to": {
        path: "/app/stats/interactions?network=".concat(this.network, "&dre=").concat(this.activeDre[this.network].dre)
      }
    }
  }, [_c('div', {
    staticClass: "flaticon-fullscreen"
  })])], 1), _c('Charts', {
    key: _vm.network,
    attrs: {
      "gatewayUrl": _vm.gatewayUrl,
      "statsPerDay": _vm.interactionsPerDay,
      "title": "Interactions",
      "fullscreen": false
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-center item-text"
  }, [_c('div', {
    staticClass: "d-flex flex-row justify-content-between"
  }, [_c('div', [_vm._v("Total: ")]), _c('div', {
    staticClass: "total-field d-flex justify-content-center"
  }, [_vm.totalInteractionsLoaded ? _c('div', {
    staticClass: "d-flex justify-content-center align-self-center"
  }, [_vm._v(" " + _vm._s(_vm.format(_vm.totalInteractions, '0,0')) + " ")]) : _c('div', {
    staticClass: "d-flex justify-content-center align-self-center dot-flashing"
  })])])])], 1), _c('div', {
    staticClass: "d-none d-md-block chart-single-wrapper"
  }, [_c('div', {
    staticClass: "chart-header"
  }, [_c('div', [_vm._v("Contracts")]), _c('router-link', {
    staticClass: "d-xl-block d-none",
    staticStyle: {
      "margin-left": "auto",
      "cursor": "pointer"
    },
    attrs: {
      "to": {
        path: "/app/stats/contracts?network=".concat(this.network, "&dre=").concat(this.activeDre[this.network].dre)
      }
    }
  }, [_c('div', {
    staticClass: "flaticon-fullscreen"
  })])], 1), _c('Charts', {
    key: _vm.network,
    attrs: {
      "gatewayUrl": _vm.gatewayUrl,
      "statsPerDay": _vm.contractsPerDay,
      "title": "Contracts",
      "fullscreen": false
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-center item-text"
  }, [_c('div', {
    staticClass: "d-flex flex-row justify-content-between"
  }, [_c('div', [_vm._v("Total: ")]), _c('div', {
    staticClass: "total-field d-flex justify-content-center"
  }, [_vm.totalContractsLoaded ? _c('div', {
    staticClass: "d-flex justify-content-center align-self-center"
  }, [_vm._v(" " + _vm._s(_vm.format(_vm.totalContracts, '0,0')) + " ")]) : _c('div', {
    staticClass: "d-flex justify-content-center align-self-center dot-flashing"
  })])])])], 1)]), _c('div', {
    staticClass: "stats-wrapper d-block d-md-none"
  }, [_c('div', {
    staticClass: "item-text total-contracts"
  }, [_c('div', [_vm._v("Total contracts")]), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm.totalContractsLoaded ? _c('div', [_c('div', [_vm._v(_vm._s(_vm.format(_vm.totalContracts, '0,0')))])]) : _c('div', {
    staticClass: "align-self-center mt-4"
  }, [_c('div', {
    staticClass: "dot-flashing"
  })])])]), _c('hr', {
    staticStyle: {
      "width": "50%"
    }
  }), _c('div', {
    staticClass: "item-text"
  }, [_c('div', [_vm._v("Total interactions")]), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_vm.totalInteractionsLoaded ? _c('div', [_c('div', [_vm._v(_vm._s(_vm.format(_vm.totalInteractions, '0,0')))])]) : _c('div', {
    staticClass: "align-self-center mt-4"
  }, [_c('div', {
    staticClass: "dot-flashing"
  })])])])])], 1), _c('div', {
    staticClass: "contracts-wrapper"
  }, [_c('div', {
    staticClass: "tx-lists-wrapper d-md-flex"
  }, [_c('div', {
    staticClass: "tx-list-single-wrapper"
  }, [_vm._m(0), _c('div', {
    staticClass: "tx-list"
  }, [!_vm.noContractsDetected ? _c('TxList', {
    attrs: {
      "paging": null
    }
  }, [_c('b-table', {
    ref: "table",
    attrs: {
      "id": "interactions-table",
      "stacked": "md",
      "hover": "",
      "items": _vm.interactions,
      "fields": _vm.interactionsFields,
      "busy": !_vm.contractsLoaded
    },
    on: {
      "row-clicked": _vm.rowClicked
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "cell(interactionId)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center fade-in-fwd"
        }, [_c('router-link', {
          attrs: {
            "to": {
              path: '/app/interaction/' + data.item.interactionId,
              query: {
                network: _vm.network,
                dre: _vm.activeDre[_vm.network].dre
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.formatIdPattern(data.item.interactionId)) + " ")]), _c('div', {
          staticClass: "table-icon-handler"
        }, [_c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard",
            value: data.item.interactionId,
            expression: "data.item.interactionId"
          }],
          staticClass: "flaticon-copy-to-clipboard small",
          attrs: {
            "title": "Copy to clipboard"
          }
        })])], 1)];
      }
    }, {
      key: "cell(contractId)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('router-link', {
          attrs: {
            "to": {
              path: '/app/contract/' + data.item.contractId,
              query: {
                network: _vm.network,
                dre: _vm.activeDre[_vm.network].dre
              },
              dre: {}
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.formatIdPattern(data.item.contractId)) + " ")]), _c('div', {
          staticClass: "table-icon-handler"
        }, [_c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard",
            value: data.item.contractId,
            expression: "data.item.contractId"
          }],
          staticClass: "flaticon-copy-to-clipboard small",
          attrs: {
            "title": "Copy to clipboard"
          }
        })])], 1)];
      }
    }, {
      key: "cell(function)",
      fn: function fn(data) {
        return [!data.item.function ? _c('div', [_vm._v("N/A")]) : _vm._e(), data.item.function && data.item.function.length > 8 ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "function-cell text-uppercase text-ellipsis",
          staticStyle: {
            "overflow": "hidden",
            "text-overflow": "ellipsis",
            "white-space": "nowrap"
          },
          attrs: {
            "title": data.item.function.toUpperCase()
          }
        }, [_vm._v(" " + _vm._s(data.item.function) + " ")]) : _c('div', {
          staticClass: "function-cell text-uppercase text-ellipsis"
        }, [_vm._v(_vm._s(data.item.function))])];
      }
    }, {
      key: "cell(source)",
      fn: function fn(data) {
        return [data.item.source == 'arweave' ? _c('div', [_c('img', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "source-icon",
          attrs: {
            "title": "Arweave",
            "src": require("../../../assets/icons/arweave-ar-logo.svg"),
            "alt": "arweave logo icon"
          }
        })]) : data.item.source == 'warp-gw' ? _c('div', [_c('img', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "source-icon",
          attrs: {
            "title": "Warp",
            "src": require("../../../assets/icons/warp\u2013logo.svg"),
            "alt": "warp logo icon"
          }
        })]) : data.item.source.includes('sequencer') ? _c('div', [_c('img', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "source-icon",
          attrs: {
            "title": "Warp Sequencer",
            "src": require("../../../assets/icons/warp\u2013logo.svg"),
            "alt": "warp logo icon"
          }
        })]) : _c('div', {
          staticClass: "source-text"
        }, [_vm._v(_vm._s(data.item.source.toUpperCase()))])];
      }
    }, {
      key: "cell(total)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(_vm._s(data.item.total))])];
      }
    }, {
      key: "cell(interactionCountdown)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right",
          staticStyle: {
            "min-width": "80px"
          }
        }, [_vm._v(" " + _vm._s(data.item.interactionCountdown ? data.item.interactionCountdown : '0s') + " ")])];
      }
    }], null, false, 1958865257)
  }), !_vm.contractsLoaded ? _c('div', _vm._l(15, function (n) {
    return _c('div', {
      key: n,
      staticClass: "preloader text-preloader tx-preloader"
    });
  }), 0) : _vm._e()], 1) : _vm._e()], 1)]), _c('div', {
    staticClass: "d-md-block tx-list-single-wrapper"
  }, [_vm._m(1), _c('div', {
    staticClass: "tx-list"
  }, [!_vm.noContractsDetected ? _c('TxList', {
    attrs: {
      "paging": null
    }
  }, [_c('b-table', {
    ref: "table",
    attrs: {
      "id": "contracts-table",
      "stacked": "md",
      "hover": "",
      "items": _vm.contracts,
      "fields": _vm.contractFields,
      "busy": !_vm.contractsLoaded
    },
    on: {
      "row-clicked": _vm.rowClicked
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "cell(contractId)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('router-link', {
          attrs: {
            "to": {
              path: '/app/contract/' + data.item.contractId,
              query: {
                network: _vm.network,
                dre: _vm.activeDre[_vm.network].dre
              }
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.formatIdPattern(data.item.contractId)) + " ")]), _c('div', {
          staticClass: "table-icon-handler"
        }, [_c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard",
            value: data.item.contractId,
            expression: "data.item.contractId"
          }],
          staticClass: "flaticon-copy-to-clipboard small",
          attrs: {
            "title": "Copy to clipboard"
          }
        })])], 1)];
      }
    }, {
      key: "cell(creator)",
      fn: function fn(data) {
        return [_c('a', {
          attrs: {
            "href": "#/app/creator/".concat(data.item.owner)
          }
        }, [_vm._v(" " + _vm._s(_vm.formatIdPattern(data.item.owner)))])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-uppercase"
        }, [_vm._v(_vm._s(data.item.type))])];
      }
    }, {
      key: "cell(source)",
      fn: function fn(data) {
        return [data.item.source.includes('warp') ? _c('div', [_c('img', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "source-icon",
          attrs: {
            "title": "Warp",
            "src": require("../../../assets/icons/warp\u2013logo.svg"),
            "alt": "warp logo icon"
          }
        })]) : data.item.source == 'arweave' ? _c('div', [_c('img', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "source-icon",
          attrs: {
            "title": "Arweave",
            "src": require("../../../assets/icons/arweave-ar-logo.svg"),
            "alt": "arweave logo icon"
          }
        })]) : data.item.source == 'sequencer' ? _c('div', [_c('img', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "source-icon",
          attrs: {
            "title": "Warp Sequencer",
            "src": require("../../../assets/icons/warp\u2013logo.svg"),
            "alt": "warp logo icon"
          }
        })]) : _c('div', {
          staticClass: "source-text"
        }, [_vm._v(_vm._s(data.item.source.toUpperCase()))])];
      }
    }, {
      key: "cell(contractCountdown)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right",
          staticStyle: {
            "min-width": "50px"
          }
        }, [_vm._v(" " + _vm._s(data.item.contractCountdown ? data.item.contractCountdown : '0s') + " ")])];
      }
    }], null, false, 822075311)
  }), !_vm.contractsLoaded ? _c('div', _vm._l(15, function (n) {
    return _c('div', {
      key: n,
      staticClass: "preloader text-preloader tx-preloader"
    });
  }), 0) : _vm._e()], 1) : _vm._e()], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tx-list-header"
  }, [_c('div', [_vm._v("Latest Interactions")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tx-list-header"
  }, [_c('div', [_vm._v("Latest Contracts")])]);
}]

export { render, staticRenderFns }