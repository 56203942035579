var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.loaded ? _c('div', {
    staticClass: "state-container"
  }, [_vm._v("Loading Contract State...")]) : _vm._e(), _vm.state ? _c('div', [_c('p', {
    staticClass: "json-header"
  }, [_vm._v(_vm._s(_vm.header))]), _c('div', {
    staticClass: "json-display"
  }, [_vm.state ? _c('json-viewer', {
    attrs: {
      "theme": "json-theme",
      "value": _vm.state,
      "expand-depth": 1,
      "copyable": "",
      "sort": ""
    },
    scopedSlots: _vm._u([{
      key: "copy",
      fn: function fn() {
        return [_c('img', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "jviewer-copy-icon",
          attrs: {
            "title": "Copy JSON data",
            "src": require("@/assets/icons/copy-to-clipboard.svg"),
            "alt": "copy icon"
          }
        })];
      },
      proxy: true
    }], null, false, 1315332213)
  }) : _vm._e(), _c('ExportButton', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Download JSON data",
      "exportData": _vm.state,
      "fileName": 'initial-state',
      "fileType": 'application/json'
    }
  })], 1)]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }