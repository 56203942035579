var render = function () {
  var _vm$contractTags, _vm$contractTags2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tags-container"
  }, [((_vm$contractTags = _vm.contractTags) === null || _vm$contractTags === void 0 ? void 0 : _vm$contractTags.length) > 0 ? _c('div', {
    staticClass: "mt-6"
  }, [_c('p', {
    staticClass: "json-header"
  }, [_vm._v("Contract tags:")])]) : _vm._e(), ((_vm$contractTags2 = _vm.contractTags) === null || _vm$contractTags2 === void 0 ? void 0 : _vm$contractTags2.length) > 0 ? _c('json-viewer', {
    attrs: {
      "value": _vm.contractTags,
      "expand-depth": 2,
      "copyable": "",
      "sort": "",
      "theme": "json-theme"
    },
    scopedSlots: _vm._u([{
      key: "copy",
      fn: function fn() {
        return [_c('img', {
          staticClass: "jviewer-copy-icon",
          attrs: {
            "src": require("@/assets/icons/copy-to-clipboard.svg"),
            "alt": "copy icon"
          }
        })];
      },
      proxy: true
    }], null, false, 15351193)
  }) : _c('p', [_vm._v("Tags are unavailable for this contract")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }