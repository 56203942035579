var render = function () {
  var _vm$paging, _vm$paging2, _vm$paging3, _vm$contracts;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.correct ? _c('div', {
    staticClass: "contract-wrapper"
  }, [_c('NetworkLabel', {
    attrs: {
      "network": _vm.network
    }
  }), _c('div', {
    staticClass: "d-block d-md-flex pl-3"
  }, [_vm._m(0), _c('div', {
    staticClass: "align-self-end pl-md-3 pl-5 contract-id d-flex"
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(_vm._s(_vm.sourceId))]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_vm._v(_vm._s(_vm._f("tx")(_vm.sourceId)))]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.sourceId,
      expression: "sourceId"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedDisplay,
      visible: _vm.copiedDisplay
    }
  }, [_vm._v("Copied")])])]), _c('div', {
    staticClass: "contract-details-wrapper pb-5"
  }, [_c('div', {
    staticClass: "d-block d-md-flex"
  }, [_c('div', {
    staticClass: "col-6 p-0"
  }, [_c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_vm._v("Creator")]), _c('div', {
    staticClass: "d-flex"
  }, [_vm.owner ? _c('div', {
    staticClass: "align-self-end d-flex"
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_c('a', {
    attrs: {
      "href": "/#/app/creator/".concat(_vm.owner, "?network=").concat(_vm.network)
    }
  }, [_vm._v(_vm._s(_vm.owner))])]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_c('a', {
    attrs: {
      "href": "https://viewblock.io/arweave/address/".concat(_vm.owner),
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm._f("tx")(_vm.owner)))])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.owner,
      expression: "owner"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopyOwner,
      expression: "onCopyOwner",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedDisplayOwner,
      visible: _vm.copiedDisplayOwner
    }
  }, [_vm._v(" Copied ")])]) : _c('div', [_c('span', [_vm._v("-")])])])]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_vm._v("Bundler source transaction id")]), _c('div', {
    staticClass: "d-flex"
  }, [_vm.bundlerSrcTxId ? _c('div', {
    staticClass: "align-self-end d-flex"
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_c('a', {
    attrs: {
      "href": "https://viewblock.io/arweave/tx/".concat(_vm.bundlerSrcTxId),
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.bundlerSrcTxId))])]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_c('a', {
    attrs: {
      "href": "https://viewblock.io/arweave/tx/".concat(_vm.bundlerSrcTxId),
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm._f("tx")(_vm.bundlerSrcTxId)))])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.bundlerSrcTxId,
      expression: "bundlerSrcTxId"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopyBundler,
      expression: "onCopyBundler",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedDisplayBundler,
      visible: _vm.copiedDisplayBundler
    }
  }, [_vm._v(" Copied ")])]) : _c('div', [_c('div', [_vm._v("N/A")])])])]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_vm._v("Total contracts")]), _c('div', [_vm._v(_vm._s((_vm$paging = _vm.paging) === null || _vm$paging === void 0 ? void 0 : _vm$paging.total))])])]), _c('div', {
    staticClass: "col-6 p-0"
  }, [_c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_vm._v("Source content type")]), _c('div', [_vm._v(_vm._s(_vm.srcContentType))])]), _c('div', {
    staticClass: "cell"
  }, [_c('div', {
    staticClass: "cell-header"
  }, [_vm._v("WASM")]), _vm.wasmLang ? _c('div', [_vm._v(_vm._s(_vm.wasmLang))]) : _c('div', [_vm._v("N/A")])])])])]), _c('div', [_c('b-nav', {
    staticClass: "contract-tabs",
    attrs: {
      "tabs": ""
    },
    on: {
      "changed": _vm.onInput
    }
  }, [_c('b-nav-item', {
    attrs: {
      "to": "?network=".concat(_vm.network, "#"),
      "active": _vm.$route.hash === '#' || _vm.$route.hash === ''
    },
    on: {
      "click": function click($event) {
        return _vm.onInput(_vm.$route.hash);
      }
    }
  }, [_vm._v(" Contracts ")]), _c('b-nav-item', {
    attrs: {
      "to": "?network=".concat(_vm.network, "#code"),
      "active": _vm.$route.hash === '#code'
    },
    on: {
      "click": function click($event) {
        return _vm.onInput(_vm.$route.hash);
      }
    }
  }, [_vm._v(" Code ")])], 1), _c('div', {
    staticClass: "tab-content"
  }, [_c('div', {
    staticClass: "p-2",
    class: ['tab-pane', {
      active: _vm.$route.hash === '#' || _vm.$route.hash === ''
    }]
  }, [!_vm.noContractsDetected ? _c('div', [_c('div', [_vm.contractsLoaded && ((_vm$paging2 = _vm.paging) === null || _vm$paging2 === void 0 ? void 0 : _vm$paging2.total) == null || ((_vm$paging3 = _vm.paging) === null || _vm$paging3 === void 0 ? void 0 : _vm$paging3.total) == 0 ? _c('div', [_vm._v(" No info about contracts. ")]) : _vm._e(), _vm.contractsLoaded ? _c('TxList', {
    attrs: {
      "paging": _vm.pages
    },
    on: {
      "page-clicked": _vm.onPageClicked
    }
  }, [((_vm$contracts = _vm.contracts) === null || _vm$contracts === void 0 ? void 0 : _vm$contracts.length) > 0 ? _c('b-table', {
    ref: "table",
    attrs: {
      "id": "interactions-table",
      "stacked": "md",
      "hover": "",
      "items": _vm.contracts,
      "fields": _vm.fields,
      "busy": !_vm.contractsLoaded
    },
    on: {
      "row-clicked": _vm.rowClicked
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('a', {
          attrs: {
            "href": "/#/app/contract/".concat(data.item.id, "?network=").concat(_vm.network)
          }
        }, [_vm._v(" " + _vm._s(_vm._f("tx")(data.item.id)))]), _c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard",
            value: data.item.id,
            expression: "data.item.id"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: function value(_ref) {
              var event = _ref.event;
              return event.stopPropagation();
            },
            expression: "({ event }) => event.stopPropagation()",
            arg: "success"
          }],
          staticClass: "flaticon-copy-to-clipboard small",
          attrs: {
            "title": "Copy to clipboard"
          }
        })])];
      }
    }, {
      key: "cell(bundlerId)",
      fn: function fn(data) {
        return [data.item.bundlerId ? _c('div', {
          staticClass: "d-flex"
        }, [_c('a', {
          attrs: {
            "href": "".concat(_vm.daysAgo(data.item.timestamp) > 1 ? "https://viewblock.io/arweave/tx/".concat(data.item.bundlerId) : "https://arweave.net/".concat(data.item.bundlerId)),
            "target": "_blank"
          }
        }, [_vm._v(_vm._s(_vm._f("tx")(data.item.bundlerId)))]), _c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard",
            value: data.item.bundlerId,
            expression: "data.item.bundlerId"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: function value(_ref2) {
              var event = _ref2.event;
              return event.stopPropagation();
            },
            expression: "({ event }) => event.stopPropagation()",
            arg: "success"
          }],
          staticClass: "flaticon-copy-to-clipboard small",
          attrs: {
            "title": "Copy to clipboard"
          }
        })]) : _c('span', [_vm._v("N/A")])];
      }
    }, {
      key: "cell(block_height)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(_vm._s(data.item.blockHeight))])];
      }
    }, {
      key: "cell(ago)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(_vm._s(data.item.ago))])];
      }
    }, {
      key: "cell(owner)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_c('a', {
          attrs: {
            "href": "https://viewblock.io/arweave/address/".concat(data.item.owner),
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(_vm._f("tx")(data.item.owner)))]), _c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard",
            value: data.item.owner,
            expression: "data.item.owner"
          }, {
            name: "clipboard",
            rawName: "v-clipboard:success",
            value: function value(_ref3) {
              var event = _ref3.event;
              return event.stopPropagation();
            },
            expression: "({ event }) => event.stopPropagation()",
            arg: "success"
          }],
          staticClass: "flaticon-copy-to-clipboard small",
          attrs: {
            "title": "Copy to clipboard"
          }
        })])];
      }
    }], null, false, 1369012346)
  }) : _vm._e()], 1) : _vm._e(), !_vm.contractsLoaded ? _c('div', _vm._l(15, function (n) {
    return _c('div', {
      key: n,
      staticClass: "preloader text-preloader tx-preloader"
    });
  }), 0) : _vm._e()], 1)]) : _c('div', {
    staticClass: "interactions-wrapper"
  }, [_vm._v("Source has no contracts!")])]), _c('div', {
    staticClass: "p-2",
    class: ['tab-pane', {
      active: _vm.$route.hash === '#code'
    }]
  }, [_vm.visitedTabs.includes('#code') ? _c('div', [_vm.loadedSource ? _c('ContractCode', {
    attrs: {
      "source": _vm.source,
      "isSourceView": true,
      "sourceId": _vm.sourceId,
      "wasm": !!_vm.wasmLang
    }
  }) : _c('div', {
    staticClass: "d-flex align-items-center flex-column pt-5"
  }, [_c('LoadingSpinner'), _c('p', [_vm._v("Loading contract code...")])], 1)], 1) : _vm._e()]), _c('div', {
    staticClass: "p-2",
    class: ['tab-pane', {
      active: _vm.$route.hash === '#state'
    }]
  }, [_c('div', [_vm.initState ? _c('ContractState', {
    attrs: {
      "contractId": _vm.contractId,
      "initState": _vm.initState
    }
  }) : _vm._e()], 1)])])], 1)], 1) : _vm._e(), !_vm.correct ? _c('div', [_c('Error')], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contract-header-wrapper"
  }, [_c('div', {
    staticClass: "flaticon-file-signature m-0-auto"
  }), _c('div', {
    staticClass: "align-self-end contract-header"
  }, [_c('span', [_vm._v("Contract Source")])])]);
}]

export { render, staticRenderFns }