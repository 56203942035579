var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tx-list"
  }, [_vm._t("default"), _vm.paging ? _c('b-pagination-nav', {
    attrs: {
      "link-gen": _vm.linkGen,
      "number-of-pages": _vm.rows,
      "use-router": "",
      "last-number": "",
      "first-number": "",
      "align": "center"
    },
    on: {
      "page-click": _vm.handleClick
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }