var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "export-link",
    on: {
      "click": _vm.exportToFile
    }
  }, [_c('img', {
    staticClass: "jviewer-download-icon",
    attrs: {
      "src": require("../assets/icons/download.svg"),
      "alt": ""
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }