var render = function () {
  var _vm$contractSrcHistor, _vm$contractSrcHistor2, _vm$contractSrcHistor3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "code-container"
  }, [_vm.loaded && ((_vm$contractSrcHistor = _vm.contractSrcHistory) === null || _vm$contractSrcHistor === void 0 ? void 0 : _vm$contractSrcHistor.length) > 1 ? _c('div', {
    staticClass: "version-nav"
  }, [_c('nav', [_c('p', {
    staticClass: "source-nav-heading"
  }, [_vm._v("Contract Source History")]), _c('ul', _vm._l(_vm.preparedSource, function (version, key) {
    return _c('li', {
      key: key,
      class: {
        'active-item': _vm.activeItem == key
      },
      on: {
        "click": function click($event) {
          return _vm.changeCodeSource(version, key);
        }
      }
    }, [_vm.activeItem == key ? _c('img', {
      staticClass: "chosen-icon",
      attrs: {
        "src": require("../../../../assets/icons/tick-circle.svg"),
        "alt": "active item circle tick icon"
      }
    }) : _vm._e(), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('p', {
      staticClass: "text-nowrap mb-0"
    }, [_vm._v(" " + _vm._s(version.age) + " ")]), _c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(_vm._f("tx")(version.srcTxId)))])])]);
  }), 0), _c('p', {
    staticClass: "source-nav-heading"
  }, [_vm._v("Compare Source")]), _vm.wasm ? _c('p', [_vm._v("Currently unavailable for WASM contracts")]) : _vm._e(), !_vm.wasm && _vm.loaded && ((_vm$contractSrcHistor2 = _vm.contractSrcHistory) === null || _vm$contractSrcHistor2 === void 0 ? void 0 : _vm$contractSrcHistor2.length) > 1 ? _c('ul', {
    staticClass: "compare-source-list"
  }, _vm._l(_vm.preparedSource, function (version, key) {
    return _c('li', {
      key: key,
      class: {
        'active-compare-item': _vm.activeCompareItem == key
      },
      on: {
        "click": function click($event) {
          return _vm.changeCompareSource(version, key);
        }
      }
    }, [_vm.activeCompareItem == key ? _c('img', {
      staticClass: "chosen-icon chosen-compare-icon",
      attrs: {
        "src": require("../../../../assets/icons/tick-circle.svg"),
        "alt": "active item circle tick icon"
      }
    }) : _vm._e(), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('p', {
      staticClass: "text-nowrap mb-0"
    }, [_vm._v(" " + _vm._s(version.age) + " ")]), _c('p', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(_vm._f("tx")(version.srcTxId)))])])]);
  }), 0) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "source-code-wrapper",
    class: ((_vm$contractSrcHistor3 = _vm.contractSrcHistory) === null || _vm$contractSrcHistor3 === void 0 ? void 0 : _vm$contractSrcHistor3.length) > 1 ? 'code-partView' : 'code-fullView'
  }, [_c('div', {
    staticClass: "code-header"
  }, [!_vm.isSourceView ? _c('a', {
    staticClass: "current-id",
    attrs: {
      "href": "/#/app/source/".concat(_vm.currentSrcTxId, "?network=").concat(_vm.network)
    }
  }, [_vm._v(_vm._s(_vm.currentSrcTxId))]) : _vm._e(), !_vm.wasm ? _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }, {
      name: "clipboard",
      rawName: "v-clipboard",
      value: function value() {
        return _vm.contractSrc;
      },
      expression: "() => contractSrc"
    }],
    staticClass: "copy-source",
    attrs: {
      "title": "Copy to clipboard"
    }
  }, [_c('p', [_vm._v("Copy source code")]), _c('div', {
    staticClass: "flaticon-copy-to-clipboard"
  }, [_vm.copiedDisplay ? _c('p', {
    staticClass: "clipboard-success copy-info"
  }, [_vm._v("Copied")]) : _vm._e()])]) : _vm._e()]), _vm.loaded && _vm.contractSrc && !_vm.wasm && _vm.renderComponent ? _c('div', [_c('code-diff', {
    attrs: {
      "old-string": _vm.sourceToCompare,
      "new-string": _vm.contractSrc,
      "output-format": "line-by-line",
      "language": "js",
      "context": 100
    }
  })], 1) : _vm._e(), _vm.loaded && !_vm.correct ? _c('div', {
    staticClass: "state-container"
  }, [_vm._v("Could not retrieve Contract Code.")]) : _vm._e(), _vm.loaded && _vm.contractSrc && _vm.wasm ? _c('div', [_c('ul', {
    attrs: {
      "id": "code-wasm"
    }
  }, _vm._l(_vm.contractSrc, function (item, idx) {
    return _c('li', {
      key: idx
    }, [_c('pre', {
      staticClass: "py-4"
    }, [_c('code', [_vm._v(_vm._s(idx.substring(idx.split('/', 4).join('/').length + 1)))])]), _c('code-diff', {
      attrs: {
        "old-string": _vm.contractSrc[idx],
        "new-string": _vm.contractSrc[idx],
        "output-format": "line-by-line",
        "language": "js",
        "context": 100
      }
    })], 1);
  }), 0)]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }