var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "acc-nav-container",
    class: [_vm.loading ? 'justify-content-center' : '', _vm.walletAccount ? 'acc-nav-big' : '']
  }, [_c('Transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_vm.walletAccount ? _c('WalletConnected', {
    attrs: {
      "account": _vm.walletAccount,
      "tokens": _vm.walletTokens
    }
  }) : _c('WalletDisconnected', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "handleMetamask": _vm.handleMetamask,
      "handleArweaveapp": _vm.handleArweaveapp
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }