var render = function () {
  var _vm$interaction, _vm$interaction2, _vm$interaction3, _vm$interaction4, _vm$interaction$inter, _vm$interaction$inter2, _vm$interaction$inter3, _vm$interaction$inter4, _vm$interaction5, _vm$interaction6, _vm$interaction7, _vm$interaction8, _vm$interaction9, _vm$interaction$inter5;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.interactionId
  }, [_vm.correct ? _c('div', {
    staticClass: "contract-wrapper"
  }, [_c('NetworkLabel', {
    attrs: {
      "network": _vm.network
    }
  }), _c('div', {
    staticClass: "d-block d-sm-flex pl-2"
  }, [_vm._m(0), _c('div', {
    staticClass: "align-self-end pl-3 contract-id d-flex"
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(_vm._s(_vm.interactionId))]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_vm._v(_vm._s(_vm._f("tx")(_vm.interactionId)))]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.interactionId,
      expression: "interactionId"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopy,
      expression: "onCopy",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedDisplay,
      visible: _vm.copiedDisplay
    }
  }, [_vm._v("Copied")])])]), _c('div', {
    staticClass: "row"
  }, [_vm.loaded ? _c('div', {
    staticClass: "pl-3 col-lg-7 col-12 info-container"
  }, [_c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Contract id")]), _c('a', {
    attrs: {
      "href": "/#/app/contract/".concat((_vm$interaction = _vm.interaction) === null || _vm$interaction === void 0 ? void 0 : _vm$interaction.contractId, "?network=").concat(this.network, "&dre=").concat(this.activeDre[this.network].dre)
    }
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(_vm._s((_vm$interaction2 = _vm.interaction) === null || _vm$interaction2 === void 0 ? void 0 : _vm$interaction2.contractId))]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_vm._v(_vm._s(_vm._f("tx")((_vm$interaction3 = _vm.interaction) === null || _vm$interaction3 === void 0 ? void 0 : _vm$interaction3.contractId)))])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: (_vm$interaction4 = _vm.interaction) === null || _vm$interaction4 === void 0 ? void 0 : _vm$interaction4.contractId,
      expression: "interaction?.contractId"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopyContractId,
      expression: "onCopyContractId",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedContractIdDisplay,
      visible: _vm.copiedContractIdDisplay
    }
  }, [_vm._v(" Copied ")])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Creator")]), _c('a', {
    attrs: {
      "href": "#/app/creator/".concat((_vm$interaction$inter = _vm.interaction.interaction) === null || _vm$interaction$inter === void 0 ? void 0 : _vm$interaction$inter.owner.address, "?network=").concat(_vm.network, "&dre=").concat(_vm.activeDre[_vm.network].dre)
    }
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(_vm._s((_vm$interaction$inter2 = _vm.interaction.interaction) === null || _vm$interaction$inter2 === void 0 ? void 0 : _vm$interaction$inter2.owner.address))]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_vm._v(_vm._s(_vm._f("tx")((_vm$interaction$inter3 = _vm.interaction.interaction) === null || _vm$interaction$inter3 === void 0 ? void 0 : _vm$interaction$inter3.owner.address)))])]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: (_vm$interaction$inter4 = _vm.interaction.interaction) === null || _vm$interaction$inter4 === void 0 ? void 0 : _vm$interaction$inter4.owner.address,
      expression: "interaction.interaction?.owner.address"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopyCreator,
      expression: "onCopyCreator",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedDisplayCreator,
      visible: _vm.copiedDisplayCreator
    }
  }, [_vm._v(" Copied ")])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Bundler id")]), _vm.interaction.bundlerTxId ? _c('div', [_c('a', {
    staticClass: "d-none d-sm-block",
    attrs: {
      "href": "".concat(_vm.daysAgo(_vm.interaction.timestamp) > 1 ? "https://viewblock.io/arweave/tx/".concat(_vm.interaction.bundlerTxId) : "https://arweave.net/".concat(_vm.interaction.bundlerTxId)),
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.interaction.bundlerTxId))]), _c('a', {
    staticClass: "d-block d-sm-none",
    attrs: {
      "href": "".concat(_vm.daysAgo(_vm.interaction.timestamp) > 1 ? "https://viewblock.io/arweave/tx/".concat(_vm.interaction.bundlerTxId) : "https://arweave.net/".concat(_vm.interaction.bundlerTxId)),
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm._f("tx")(_vm.interaction.bundlerTxId)))])]) : _c('div', [_vm._v("N/A")]), _vm.interaction.bundlerTxId ? _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: (_vm$interaction5 = _vm.interaction) === null || _vm$interaction5 === void 0 ? void 0 : _vm$interaction5.bundlerTxId,
      expression: "interaction?.bundlerTxId"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopyBundlerId,
      expression: "onCopyBundlerId",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  }) : _vm._e(), _c('p', {
    staticClass: "clipboard-success",
    class: {
      hidden: !_vm.copiedDisplayBundlerId,
      visible: _vm.copiedDisplayBundlerId
    }
  }, [_vm._v(" Copied ")])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Sort key")]), _c('div', {
    staticStyle: {
      "word-break": "break-all"
    }
  }, [_vm._v(" " + _vm._s(_vm.interaction.sortKey ? _vm.interaction.sortKey : 'N/A') + " ")]), _vm.interaction.sortKey ? _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: (_vm$interaction6 = _vm.interaction) === null || _vm$interaction6 === void 0 ? void 0 : _vm$interaction6.sortKey,
      expression: "interaction?.sortKey"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopySortKey,
      expression: "onCopySortKey",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard mb-4",
    attrs: {
      "title": "Copy to clipboard"
    }
  }) : _vm._e(), _c('p', {
    staticClass: "clipboard-success mb-4",
    class: {
      hidden: !_vm.copiedDisplaySortKey,
      visible: _vm.copiedDisplaySortKey
    }
  }, [_vm._v(" Copied ")])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Last Sort key")]), _c('div', {
    staticStyle: {
      "word-break": "break-all"
    }
  }, [_vm._v(" " + _vm._s(_vm.interaction.lastSortKey ? _vm.interaction.lastSortKey : 'N/A') + " ")]), _vm.interaction.lastSortKey ? _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: (_vm$interaction7 = _vm.interaction) === null || _vm$interaction7 === void 0 ? void 0 : _vm$interaction7.lastSortKey,
      expression: "interaction?.lastSortKey"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.onCopyLastSortKey,
      expression: "onCopyLastSortKey",
      arg: "success"
    }],
    staticClass: "flaticon-copy-to-clipboard mb-4",
    attrs: {
      "title": "Copy to clipboard"
    }
  }) : _vm._e(), _c('p', {
    staticClass: "clipboard-success mb-4",
    class: {
      hidden: !_vm.copiedDisplayLastSortKey,
      visible: _vm.copiedDisplayLastSortKey
    }
  }, [_vm._v(" Copied ")])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Confirmation status")]), _c('div', [_vm._v(_vm._s(_vm.interaction.confirmationStatus))])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Confirming peers")]), _vm.interaction.confirmingPeer && _vm.interaction.confirmingPeer[0] != 'N/A' ? _c('div', [_c('a', {
    staticClass: "mr-1",
    attrs: {
      "href": "".concat(_vm.interaction.source && _vm.interaction.source == 'arweave' ? "http://".concat(_vm.interaction.confirmingPeer[0], ":1984/tx/").concat(_vm.interactionId, "/status") : "https://node1.bundlr.network"),
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.interaction.confirmingPeer[0]))]), _c('a', {
    staticClass: "mr-1",
    attrs: {
      "href": "".concat(_vm.interaction.source && _vm.interaction.source == 'arweave' ? "http://".concat(_vm.interaction.confirmingPeer[1], ":1984/tx/").concat(_vm.interactionId, "/status") : "https://node1.bundlr.network"),
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.interaction.confirmingPeer[1]))]), _c('a', {
    staticClass: "mr-1",
    attrs: {
      "href": "".concat(_vm.interaction.source && _vm.interaction.source == 'arweave' ? "http://".concat(_vm.interaction.confirmingPeer[2], ":1984/tx/").concat(_vm.interactionId, "/status") : "https://node1.bundlr.network"),
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.interaction.confirmingPeer[2]))])]) : _c('div', [_vm._v("N/A")])]), _c('div', {
    staticClass: "interaction-item error-message"
  }, [_c('div', [_vm._v("Error")]), _c('div', {
    class: _vm.errorMessage ? 'error-text' : ''
  }, [_vm._v(_vm._s(_vm.errorMessage ? _vm.errorMessage : 'N/A'))])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Function")]), _c('div', [_vm._v(_vm._s(_vm.interaction.func))])]), _vm.interaction ? _c('div', [_c('p', {
    staticClass: "json-header"
  }, [_vm._v("Input Value")]), _c('json-viewer', {
    attrs: {
      "value": _vm.interaction.interactionValue,
      "expand-depth": 2,
      "copyable": "",
      "sort": "",
      "theme": "json-theme"
    },
    scopedSlots: _vm._u([{
      key: "copy",
      fn: function fn() {
        return [_c('img', {
          staticClass: "jviewer-copy-icon",
          attrs: {
            "src": require("@/assets/icons/copy-to-clipboard.svg"),
            "alt": "copy icon"
          }
        })];
      },
      proxy: true
    }], null, false, 15351193)
  })], 1) : _vm._e(), _vm.interaction.vrf ? _c('div', {
    staticClass: "vrf-container"
  }, [_c('p', {
    staticClass: "json-header"
  }, [_vm._v("VRF")]), _c('json-viewer', {
    attrs: {
      "value": _vm.interaction.vrf,
      "expand-depth": 2,
      "copyable": "",
      "sort": "",
      "theme": "json-theme"
    },
    scopedSlots: _vm._u([{
      key: "copy",
      fn: function fn() {
        return [_c('img', {
          staticClass: "jviewer-copy-icon",
          attrs: {
            "src": require("@/assets/icons/copy-to-clipboard.svg"),
            "alt": "copy icon"
          }
        })];
      },
      proxy: true
    }], null, false, 15351193)
  })], 1) : _vm._e(), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("PST transfer qty")]), _c('div', [_vm._v(_vm._s(_vm.interaction.pstQty ? _vm.interaction.pstQty : 'N/A'))])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Block height")]), _c('a', {
    attrs: {
      "href": "https://viewblock.io/arweave/block/".concat(_vm.interaction.blockHeight),
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.interaction.blockHeight))])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Block id")]), _c('a', {
    attrs: {
      "href": "https://arweave.net/block/hash/".concat(_vm.interaction.blockId),
      "target": "_blank"
    }
  }, [_c('span', {
    staticClass: "d-none d-sm-block"
  }, [_vm._v(_vm._s((_vm$interaction8 = _vm.interaction) === null || _vm$interaction8 === void 0 ? void 0 : _vm$interaction8.blockId))]), _c('span', {
    staticClass: "d-block d-sm-none"
  }, [_vm._v(_vm._s(_vm._f("tx")((_vm$interaction9 = _vm.interaction) === null || _vm$interaction9 === void 0 ? void 0 : _vm$interaction9.blockId)))])])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Recipient")]), _c('div', [_vm._v(_vm._s(_vm.interaction.recipient))])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Timestamp")]), _c('div', [_vm._v(_vm._s(_vm.interaction.timestamp) + " (" + _vm._s(_vm.interaction.timestampFormatted) + ")")])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Fee")]), _c('div', [_vm._v(" " + _vm._s(_vm.interaction.fee) + " (" + _vm._s(_vm.interaction.feeInAr) + " AR / "), _c('a', {
    attrs: {
      "href": "https://app.redstone.finance/#/app/token/AR",
      "target": "_blank"
    }
  }, [_vm._v("$" + _vm._s(_vm.interaction.feeInUsd))]), _vm._v(") ")])]), _c('div', {
    staticClass: "interaction-item"
  }, [_c('div', [_vm._v("Quantity")]), _c('div', [_vm._v(_vm._s((_vm$interaction$inter5 = _vm.interaction.interaction) === null || _vm$interaction$inter5 === void 0 ? void 0 : _vm$interaction$inter5.quantity.winston))])])]) : _vm._e(), !_vm.loaded ? _c('div', {
    staticClass: "pl-3 col-lg-7 col-12 info-container"
  }, _vm._l(11, function (n) {
    return _c('div', {
      key: n,
      staticClass: "preloader text-preloader tx-preloader"
    });
  }), 0) : _vm._e(), _c('div', {
    staticClass: "col-lg-5 col-12 pt-4"
  }, [_vm.interaction ? _c('div', [_c('p', {
    staticClass: "json-header"
  }, [_vm._v("Interaction Tags")]), !_vm.loaded ? _c('json-viewer', {
    attrs: {
      "value": '',
      "expand-depth": 2,
      "copyable": "",
      "sort": "",
      "theme": "json-theme"
    }
  }) : _c('json-viewer', {
    attrs: {
      "value": _vm.interaction.tags,
      "expand-depth": 2,
      "copyable": "",
      "sort": "",
      "theme": "json-theme"
    },
    scopedSlots: _vm._u([{
      key: "copy",
      fn: function fn() {
        return [_c('img', {
          staticClass: "jviewer-copy-icon",
          attrs: {
            "src": require("@/assets/icons/copy-to-clipboard.svg"),
            "alt": "copy icon"
          }
        })];
      },
      proxy: true
    }], null, false, 15351193)
  })], 1) : _vm._e()])])], 1) : _vm._e(), !_vm.correct ? _c('div', [_c('Error')], 1) : _vm._e()]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contract-header-wrapper"
  }, [_c('div', {
    staticClass: "flaticon-interaction"
  }), _c('div', {
    staticClass: "align-self-end contract-header"
  }, [_vm._v("Interaction")])]);
}]

export { render, staticRenderFns }