var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fullscreen-wrapper",
    staticStyle: {
      "marginbottom": "30px"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "height": "100%"
    }
  }, [_c('a', {
    attrs: {
      "href": _vm.returnUrl
    }
  }, [_vm._m(0)]), _c('h1', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticStyle: {
      "height": "calc(100% - 70px)",
      "width": "100%"
    }
  }, [_c('Charts', {
    attrs: {
      "gatewayUrl": _vm.gatewayUrl,
      "statsPerDay": _vm.perDay,
      "title": _vm.title,
      "fullscreen": true
    }
  })], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex mb-2"
  }, [_c('div', {
    staticClass: "flaticon-chevron-left"
  }), _c('div', [_vm._v("Return")])]);
}]

export { render, staticRenderFns }