var render = function () {
  var _vm$tokens, _vm$tokens2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authorized-view"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "header-id"
  }, [_c('p', [_c('img', {
    attrs: {
      "src": require("../../assets/icons/wallet-svgrepo-com.svg"),
      "alt": "wallet icon"
    }
  }), _vm._v(_vm._s(_vm._f("tx")(_vm.account)))]), _c('div', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: _vm.account,
      expression: "account"
    }],
    staticClass: "flaticon-copy-to-clipboard",
    attrs: {
      "title": "Copy to clipboard"
    }
  })]), _c('BaseButton', {
    staticClass: "refresh-btn",
    attrs: {
      "type": 'secondary'
    },
    on: {
      "btnClicked": _vm.getTokenBalance
    }
  }, [_vm._v(" Refresh ")])], 1), _c('div', {
    staticClass: "table-container"
  }, [((_vm$tokens = _vm.tokens) === null || _vm$tokens === void 0 ? void 0 : _vm$tokens.length) > 0 ? _c('b-table', {
    ref: "table",
    attrs: {
      "id": "tokens-table",
      "per-page": _vm.perPage,
      "stacked": "md",
      "hover": "",
      "small": true,
      "items": _vm.tokens,
      "fields": _vm.fields,
      "current-page": _vm.currentPage,
      "busy": _vm.tableLoading
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex flex-column justify-content-center align-items-center"
        }, [_c('LoadingSpinner'), _c('strong', [_vm._v("Loading tokens...")])], 1)];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function fn(data) {
        return [data.item.contract_tx_id ? _c('div', [_c('router-link', {
          attrs: {
            "to": {
              path: '/app/contract/' + data.item.contract_tx_id,
              query: {
                network: _vm.network
              }
            }
          },
          on: {
            "click": _vm.changeModalVisible
          }
        }, [_vm._v(" " + _vm._s(_vm._f("tx")(data.item.contract_tx_id)) + " ")])], 1) : _c('div', [_vm._v("N/A")])];
      }
    }, {
      key: "cell(name)",
      fn: function fn(data) {
        return [data.item.token_name ? _c('div', {
          staticStyle: {
            "width": "280px",
            "overflow": "hidden",
            "text-overflow": "ellipsis",
            "white-space": "nowrap"
          }
        }, [_vm._v(" " + _vm._s(data.item.token_name) + " ")]) : _c('div', {
          staticStyle: {
            "width": "280px",
            "overflow": "hidden",
            "text-overflow": "ellipsis",
            "white-space": "nowrap"
          }
        }, [_vm._v("N/A")])];
      }
    }, {
      key: "cell(balance)",
      fn: function fn(data) {
        return [data.item.balance ? _c('div', {
          directives: [{
            name: "clipboard",
            rawName: "v-clipboard",
            value: data.item.balance,
            expression: "data.item.balance"
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticStyle: {
            "max-width": "210px",
            "overflow": "hidden",
            "text-overflow": "ellipsis",
            "white-space": "nowrap",
            "cursor": "pointer",
            "text-decoration": "underline"
          },
          attrs: {
            "title": "Copy to clipboard"
          }
        }, [_vm._v(" " + _vm._s(data.item.balance) + " ")]) : _c('div', {
          staticStyle: {
            "max-width": "210px",
            "overflow": "hidden",
            "text-overflow": "ellipsis",
            "white-space": "nowrap"
          }
        }, [_vm._v("N/A")])];
      }
    }], null, false, 2413342823)
  }) : _c('div', {
    staticClass: "no-tokens-info"
  }, [_c('p', [_vm._v("You have no tokens!")])])], 1), ((_vm$tokens2 = _vm.tokens) === null || _vm$tokens2 === void 0 ? void 0 : _vm$tokens2.length) > 0 ? _c('b-pagination', {
    attrs: {
      "total-rows": _vm.rows,
      "per-page": _vm.perPage,
      "aria-controls": "tokens-table",
      "align": "center",
      "last-number": "",
      "first-number": ""
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "footer"
  }, [_c('BaseButton', {
    on: {
      "btnClicked": _vm.switchWallet
    }
  }, [_vm._v("Switch wallet")]), _c('BaseButton', {
    attrs: {
      "type": 'textOnly'
    },
    on: {
      "btnClicked": _vm.deleteAccount
    }
  }, [_vm._v(" Disconnect ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }